<template>
  <div>
    <div class="row">
      <b-button block variant="success" v-b-toggle.filter class="m-1">Filtros</b-button>
      <div class="col-12">
        <b-collapse id="filter">
          <div class="row">
            <div class="col-12 mb-1">
              <v-select
                    v-model="area"
                    :reduce="(option) => option.id"
                    label="title"
                    @input="searchArea"
                    :options="area_options"
                    placeholder = "Provincia"
              />
            </div>
            <div class="col-12 mb-1">
              <v-select
                    v-model="location"
                    :reduce="(option) => option.title"
                    label="title"
                    @input="searchArea"
                    :options="location_options"
                    placeholder = "Localidad"
              />
            </div>
            <div class="col-12 mb-1">
              <v-select
                    v-model="type"
                    :reduce="(option) => option.id"
                    label="title"
                    @input="searchArea"
                    :options="type_options"
                    placeholder = "Tipo Colaborador"
              />
            </div >
              <b-button variant="primary" class="m-1" @click="reset">Reset</b-button>
          </div>
        </b-collapse>
      </div>
    </div>
    <b-table 
      :items="rows"
      :fields = "fields"
      thead-class="d-none"
      @row-clicked="showData"      
    >
      <template #cell(avatar)= "data">
        <img class="img-avatar" :src="$http.defaults.baseURL + 'storage/' + data.value" alt="avatar">
      </template>
    </b-table>
    <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        v-model="open"
        right
        backdrop
        shadow
      >
        <template #default="{ hide }">
          <div class="p-3 text-center">
            <img class="img-avatar mb-1" :src="partner.image ? $http.defaults.baseURL + 'storage/' + partner.image : ''" alt="avatar">
            <h4 id="sidebar-no-header-title">{{partner.business}}</h4>
            <hr>
            <p>{{partner.name}}</p>
            <p>( {{partner.location}} )</p>
            <b-form-textarea
              id="message"
              size="sm"
              name="message"
              placeholder="Mensaje"
              rows ="10"
              no-resize 
              class="mb-2"   
            />        
            
            <b-button variant="success" block >Contactar</b-button>
            <b-button variant="primary" block @click="closeSidebar">Cerrar</b-button>
          </div>


        </template>
      </b-sidebar>      
  </div>
</template>
<script>
import vSelect from "vue-select";
import { 
  BTable,
  BSidebar,
  VBToggle,
  BButton,
  BFormTextarea,
  BCollapse
  } from "bootstrap-vue";

export default {
  components : {
    BTable,
    BSidebar,
    BCollapse,
    BFormTextarea,    
    BButton,
    vSelect
  },
      directives: {
    'b-toggle': VBToggle
  },
  data() {
    return {
      rows : [],
      fields : ['avatar','business_name'],
      partner : {
         business : '',
         avatar : '',
         name  : '' 
      },
      area : null,
      type : null,
      location : null,
      area_options : [{title: 'Barcelona',id : 1 },{title: 'Girona',id : 2 },{title: 'Tarragona',id : 3 }],
      type_options : [{title: 'Autónomo',id : 1 },{title: 'Empresa',id : 2 },{title: 'Subcontrata',id : 3 }],
      location_options : [{title: 'Barcelona',id : 1 },{title: 'Mataro',id : 2 },{title: 'Cardedeu',id : 3 }],
      open : false
    }
  },
  mounted() {
      this.$http.get("/api/getPartners").then((response) => {
        this.rows = response.data
      });
  },
  methods: {
    showData(record,id){
      this.partner.business = record.business_name
      this.partner.image = record.avatar
      this.partner.name = record.name + ' ' + record.surname
      this.partner.location =  record.locality
      this.open = true
    },
    closeSidebar() {
      this.open = false
    },
    reset() {
      this.area = this.type = this.location = null;
      this.$http.get("/api/getPartners").then((response) => {
        this.rows = response.data
      });
    },
    searchArea() {
      const params = new URLSearchParams([['area', this.area ],['type' , this.type],['location', this.location]]);
      this.$http.get("/api/getPartners", {params}).then((response) => {
        this.rows = response.data
      });
    } 
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
  .img-avatar {
    max-width: 64px;
  }
</style>